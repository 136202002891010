@import 'src/styles/colors.scss';
@import 'src/styles/mixins.scss';

.wrapper {
  flex: 1;
  max-width: 940px;
  position: relative;

  .inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 53px 36px 43px 36px;
    background-color: $mainWhite;

    @include large {
      padding-left: 50px;
      padding-right: 50px;
    }

    > * {
      margin-bottom: 29px;
    }
  }
}
