@import 'src/styles/typography.scss';
@import 'src/styles/mixins.scss';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 0 auto;

  .text {
    margin-left: 36px;
  }
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.containerButtons {
  display: flex;
  gap: 50px;
}

.containerImg {
 max-width: 220px;
  max-height:241px ;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}

.marginTen {
  margin-bottom: 27px;
}

.marginDouble {
  margin-bottom: 60px;
}


@media screen and (max-width: 800px) {
  .containerImg {
    max-width: 180px;
    max-height:197px ;
  }
}
