@import 'src/styles/mixins.scss';

.wrapper {
  margin: 0 auto;
  margin-top: 19px;

  .button {
    margin-top: 32px;
  }

  @include large {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    margin-left: 0;
    margin-right: 0;
    width: 100%;

    .button {
      width: 340px;
      margin-top: 0;
    }
  }
}
