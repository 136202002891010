@import 'src/styles/colors.scss';

.wrapper {
  display: flex;
  align-items: center;

  .step {
    position: relative;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 24px;
    background-color: $mainLightBlue;
    transition: all 0.5s;

    &:first-of-type {
      border-radius: 3px 0px 0px;
    }

    &:last-of-type {
      border-radius: 0px 3px 0px 0px;
    }

    .stepNumber {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 36px;
      height: 36px;
      margin-right: 12px;
      border-radius: 100%;
      background-color: $mainLightGray;
      color: $mainGray;
      transition: all 0.5s;
    }

    .stepLabel {
      color: $mainGray;
      transition: all 0.5s;
    }

    &:after {
      content: '';
      position: absolute;
      width: 17px;
      height: 17px;
      bottom: -8.5px;
      transform: rotate(45deg);
      opacity: 0;
      background: $mainLightBlue;
      transition: all 0.5s;
    }

    &.active {
      background-color: $mainLightGray;

      .stepNumber {
        background-color: $mainRed;
        color: $mainWhite;
      }

      .stepLabel {
        color: $mainBlack;
      }

      &:after {
        opacity: 1;
        background: $mainLightGray;
      }
    }

    &.passed {
      .stepNumber {
        background-color: $mainBlue;
        color: $mainWhite;
      }
    }
  }
}
